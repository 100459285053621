import React from "react"

import { useIntl } from "react-intl"
import Card from "react-bootstrap/Card"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Layout1 from "../components/layout_1"
import FormattedMarkdown from "../components/formatted_markdown"

const TermsAndConditionsPage = ({ location }) => {
  const intl = useIntl()
  const pageTitle = intl.formatMessage({ id: "Terms and Conditions" })
  return (
    <Layout pageTitle={pageTitle} location={location}>
      <SEO title={pageTitle} />
      <Layout1
        center={
          <Card>
            <Card.Body>
              <Card.Text style={{ textAlign: "justify" }}>
                <FormattedMarkdown id="Terms and Conditions.Terms and Conditions" />
              </Card.Text>
            </Card.Body>
          </Card>
        }
      />
    </Layout>
  )
}

export default TermsAndConditionsPage
